import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";

//create function component as login
export default function ProfilePasswordUpdate() {

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" target="_blank">
                        <h4>Update Password</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Old Password</label>
                                    <input type="password" className="form-control shadow-none" readOnly />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">New Password</label>
                                    <input type="password" className="form-control shadow-none" readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Retype New Password</label>
                                    <input type="password" className="form-control shadow-none" readOnly />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Cancel</Link>
                            <a href="restaurant-info.html" className="btn theme-btn mt-0 w-100">Update</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}