import {useState} from 'react';
import { useGlobalContext } from "./GlobalContext";
import { validatePhoneNumber } from './service/Validation';
import AuthService from "./service/AuthService";

//create function component as login
export default function Login() {

    const [phoneNumber, setPhoneNumber] = useState("");
    const { setIsLoading, setAppError, setAppErrorMessage  } = useGlobalContext();

    //create a function called sentOTP
    const sentOTP = async () => {
        //check if the value is empty
        if (phoneNumber === "") {
            //if empty, show an alert
            setAppError(true);
            setAppErrorMessage("Please enter your mobile number");
        } else {

            if( !validatePhoneNumber(phoneNumber) ){
                setAppError(true);
                setAppErrorMessage("Please enter a 10 digit mobile number");
                return;
            }
            
            setIsLoading(true);
            var response = await AuthService.sentLoginOtp(phoneNumber);
            setIsLoading(false);
            if( response ){
                window.location.href = "/loginotp/" + phoneNumber;
            }
            else{
                setAppError(true);
                setAppErrorMessage("Please enter a valid mobile number");
                return;
            }
        }
    }

    return (
        <>
            <section className="section-b-space pt-0">
                <img className="img-fluid login-img" src="./assets/system/pages/login-mobile-entry.png" alt="login-img" />

                <div className="custom-container">
                    <div className="auth-form mt-1">
                        <div className="form-group mt-4">
                            <label className="form-label fw-semibold dark-text">Mobile Number</label>
                            <div className="d-flex gap-3">
                                <div className="form-input dark-border-gradient">
                                    <input type="number" className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Enter Phone Number" maxLength={10} minLength={10} />
                                </div>
                            </div>
                        </div>

                        <button type="button" onClick={(e) => sentOTP()} className="btn theme-btn w-100 mt-4" role="button">Send OTP</button>
                    </div>

                    <p className="text-center">By continuing, you agree to our Terms of service Privacy Policy Content Policy</p>
                </div>
            </section>
        </>
    );
}