import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import { useState } from "react";
import { useEffect } from 'react';
import KitchenFunctions from "./service/KitchenService";

//create function component as login
export default function ProfileUpdate() {

    const { setIsLoading, setAppError, setAppErrorMessage, appKitchen } = useGlobalContext();

    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [landmark, setLandmark] = useState("");
    const [pincode, setPincode] = useState("");

    const initFormData = () => {
        setName(appKitchen.name);
        setBio(appKitchen.bio);
        setAddressLine1(appKitchen.addressLine1);
        setAddressLine2(appKitchen.addressLine2);
        setLandmark(appKitchen.landmark);
        setPincode(appKitchen.pincode);
    };

    //useEffect to call initFormData on component load
    useEffect(() => {
        initFormData();
    }, [appKitchen]);
    
    const updateDetails = async () => {
        setIsLoading(true);
        KitchenFunctions.updateDetails(name, bio, addressLine1, addressLine2, pincode);
        setIsLoading(false);
        alert("Details updated");
        window.location.href = "/profilemenu";
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="auth-form" target="_blank">
                        <h4>Basic Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Kitchen Name</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter restaurant name" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Bio</label>
                                    <textarea type="text" className="form-control shadow-none" rows="3"
                                        placeholder="Enter description" value={bio} onChange={(e) => setBio(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Address Line 1</label>
                                    <input type="text" className="form-control shadow-none"
                                        placeholder="Enter restaurant address line 1"  value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Address Line 2</label>
                                    <input type="text" className="form-control shadow-none"
                                        placeholder="Enter restaurant address line 2" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">City</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter city" readOnly value={appKitchen.city.name} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Pincode</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Cancel</Link>
                            <button type="button" className="btn theme-btn mt-0 w-100" onClick={updateDetails}>Save</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}