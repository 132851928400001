import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import KitchenFunctions from "./service/KitchenService";

//create function component as login
export default function ProfileContactUpdate() {

    const { setIsLoading, setAppError, setAppErrorMessage, appKitchen } = useGlobalContext();

    const [mobileAlternate, setMobileAlternate] = useState("");
    const [emailPrimary, setEmailPrimary] = useState("");
    const [emailAlternate, setEmailAlternate] = useState("");

    const initFormData = () => {
        setMobileAlternate(appKitchen.mobileAlternate);
        setEmailPrimary(appKitchen.emailPrimary);
        setEmailAlternate(appKitchen.emailAlternate);
    };

    //useEffect to call initFormData on component load
    useEffect(() => {
        initFormData();
    }, [appKitchen]);

    const updateContact = async () => {
        setIsLoading(true);
        KitchenFunctions.updateContact(appKitchen.mobilePrimary, mobileAlternate, emailPrimary, emailAlternate);
        setIsLoading(false);
        alert("Contacts updated");
        window.location.href = "/profilemenu";
    }

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" target="_blank">
                        <h4>Contact Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Primary Mobile</label>
                                    <input type="number" className="form-control shadow-none" placeholder="10 digit mobile number" readOnly value={appKitchen.mobilePrimary} />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Alternate Mobile</label>
                                    <input type="number" className="form-control shadow-none" placeholder="10 digit mobile number" value={mobileAlternate} onChange={(e) => setMobileAlternate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Primary Email</label>
                                    <input type="email" className="form-control shadow-none" placeholder="name@domain.com" readOnly value={emailPrimary} onChange={(e) => setEmailPrimary(e.target.value)}  />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Alternate Email</label>
                                    <input type="email" className="form-control shadow-none" placeholder="name@domain.com" value={emailAlternate} onChange={(e) => setEmailAlternate(e.target.value)}  />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Cancel</Link>
                            <button type="button" className="btn theme-btn mt-0 w-100" onClick={(e)=>updateContact()}>Save</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}