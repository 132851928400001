import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function CouponAdd() {

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="header">
                        <div className="head-content">
                            <h2>Create New Coupon</h2>
                        </div>
                        <Link to="/couponlist">
                            <i className="ri-list-check"></i>
                        </Link>
                    </div>
                    <form className="auth-form" target="_blank">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label fw-semibold">Title</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Sub Title</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter sub title" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Code</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter code" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Min Purchase</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter min purchase" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Start Date</label>
                                    <input type="date" className="form-control shadow-none" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">End Date</label>
                                    <input type="date" className="form-control shadow-none" />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Discount</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter discount" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Coupon Type</label>
                                    <select className="form-select shadow-none" aria-label="Default select example" defaultValue="2">
                                        <option value="1">Percentage</option>
                                        <option value="2">Amount</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Max Discount As Amount</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter max discount" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">For Whom</label>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="buyerType" id="buyerType1" />
                                        <label className="form-check-label dark-text" htmlFor="buyerType1">First Time Buyer</label>
                                    </div>

                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="buyerType" id="buyerType2" />
                                        <label className="form-check-label dark-text" htmlFor="buyerType2">All Buyers</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">How many time a buyer can use this</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter max usage / buyer" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">How many it can be used overall</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter max usage count" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}