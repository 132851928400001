//write a function to validate a string is having 10 digits none other than number
function validatePhoneNumber(phoneNumber) {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
}

//write a function to validate string whethere it is having proper email format
function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

//export all the above functions
export { validatePhoneNumber, validateEmail };