import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import { useGlobalContext } from "./GlobalContext";
import { useState } from "react";
import { useEffect } from 'react';
import KitchenKycFunctions from "./service/KitchenKycService";

//create function component as login
export default function ProfileBankUpdate() {

    const { setIsLoading, setAppError, setAppErrorMessage, appKitchen } = useGlobalContext();

    const [kitchenKyc, setKitchenKyc] = useState(null);

    const getKycData = async () => {
        try {
            setIsLoading(true);
            const response = await KitchenKycFunctions.getKyc();
            console.log("response : " + response);
            setKitchenKyc(response);
        } catch (error) {
            setAppError(true);
            setAppErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getKycData();
    }, []);

    if (kitchenKyc === null) {
        return <div>Loading...</div>; // Show a loading message until data is fetched
      }

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" target="_blank">
                        <h4>Bank Details</h4>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Account Name</label>
                                    <input type="text" className="form-control shadow-none" placeholder="" readOnly value={kitchenKyc.data.bankAcName} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Account Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="" readOnly value={kitchenKyc.data.bankAcNumber} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">IFSC</label>
                                    <input type="text" className="form-control shadow-none" placeholder="" readOnly value={kitchenKyc.data.bankIfsc} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="fixed-btn">
                    <div className="custom-container">
                        <div className="d-flex align-items-center flex-nowrap gap-2">
                            <Link to="/profilemenu" className="btn gray-btn mt-0 w-100">Go Back</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}