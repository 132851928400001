import { Link } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";

export default function MenuFooter() {

    return (
        <>

            <section className="panel-space"></section>

            <div className="navbar-menu">
                <ul>
                    <li className="active w-25">
                        <Link to="/dashboard" className="icon">
                            <img className="unactive" src="../assets/images/svg/home.svg" alt="home" />
                            <img className="active" src="../assets/images/svg/home-fill.svg" alt="home" />
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="w-25">
                        <Link to="/ordersearch/new" className="icon">

                            <img className="unactive" src="../assets/images/svg/receipt.svg" alt="receipt" />
                            <img className="active" src="../assets/images/svg/my-order-fill.svg" alt="receipt" />
                            <span>Order</span>
                        </Link>
                    </li>
                    <li className="w-25">
                        <Link to="/wallet" className="icon">

                            <img className="unactive" src="../assets/images/svg/earning.svg" alt="earning" />
                            <img className="active" src="../assets/images/svg/earning-fill.svg" alt="earning" />
                            <span>wallet</span>
                        </Link>
                    </li>

                    <li className="w-25">
                        <Link to="/profilemenu" className="icon">

                            <img className="unactive" src="../assets/images/svg/user.svg" alt="user" />
                            <img className="active" src="../assets/images/svg/user-fill.svg" alt="user" />
                            <span>Profile</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
}