import { Link } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";
import AuthFunctions from "./service/AuthService";
//import GlobalContext.js
import { useGlobalContext } from "./GlobalContext";

//create function component as login
export default function ProfileMenu() {

    const { setIsLoading, setAppError, setAppErrorMessage, appKitchen } = useGlobalContext();

    return (
        <>
            <MenuSide />

            <section>
                <div className="custom-container">
                    <div className="profile-cover">
                        <img className="img-fluid profile-pic" src={process.env.REACT_APP_API_URL + appKitchen.logoUrl} alt="profile" />
                    </div>
                    <div className="profile-name">
                        <h5>{appKitchen.name}</h5>
                        <h6>{appKitchen.emailPrimary}</h6>
                        <h6>{appKitchen.mobilePrimary}</h6>
                    </div>
                </div>
            </section>
            
            <section>
                <div className="custom-container">
                    <ul className="profile-list">
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/svg/profile.svg" alt="profile" />
                                </div>
                                <Link to="/profileupdate" className="profile-box-name">
                                    <h5>Profile Update</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/svg/notification-bing.svg" alt="bell" />
                                </div>
                                <Link to="/profilecontactupdate" className="profile-box-name">
                                    <h5>Contact Details</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>

                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/restaurant-partner/svg/bank.svg" alt="location" />
                                </div>
                                <Link to="/profilebankupdate" className="profile-box-name">
                                    <h5>Bank Details</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>

                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/restaurant-partner/svg/card-tick.svg" alt="wishlist" />
                                </div>
                                <Link to="/profilekycupdate" className="profile-box-name">
                                    <h5>KYC Verification</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/svg/my-order.svg" alt="order" />
                                </div>
                                <Link to="/kitchenmenulist" className="profile-box-name">
                                    <h5>Kitchen Menu</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/svg/ticket.svg" alt="ticket" />
                                </div>
                                <Link to="/couponlist" className="profile-box-name">
                                    <h5>Coupon</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="profile-box">
                                <div className="profile-icon">
                                    <img className="img-fluid icon" src="../assets/images/svg/logout.svg" alt="logout" />
                                </div>
                                <Link onClick={AuthFunctions.logout} className="profile-box-name">
                                    <h5>Log Out</h5>
                                    <i className="ri-arrow-right-s-line arrow"></i>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <MenuFooter />
        </>
    );
}