import { Link } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";
import AuthFunctions from "./service/AuthService";

//create function component as login
export default function BrandOfficier() {

    return (
        <>
            <MenuSide />

            <section>
                <div className="custom-container">
                    <div className="profile-cover">
                        <img className="img-fluid profile-pic" src="../assets/images/icons/profile.png" alt="profile" />
                    </div>
                    <div className="profile-name">
                        <h5>Ashok Raj</h5>
                        <h6>ashokrajkp@gmail.com</h6>
                    </div>
                </div>
            </section>
            
            <section>
                <div className="brand-info">
                    
                </div>
            </section>

            <MenuFooter />
        </>
    );
}