import axios from 'axios';
//import GlobalContext

const API_URL = process.env.REACT_APP_API_URL;

const sentLoginOtp = async (mobile) => {
    let formData = new FormData();
    formData.append('mobile', mobile);
    
    const response = axios.post(API_URL + 'user-send-otp.php', formData);
    const reply = await response;
    
    console.log("reply : " + reply);
    console.log("reply.data : " + reply.data);
    if (reply.data.status === 200 ){
        return true;
    }
    else{
        return false;
    }
};

const login = async (mobile, otp) => {
    let formData = new FormData();
    formData.append('mobile', mobile);
    formData.append('otp', otp);
    formData.append('role', 'kitchen');

    const response = axios.post(API_URL + 'user-verify-otp.php', formData);
    const reply = await response;
    console.log("reply : " + reply);
    console.log("reply data : " + reply.data);
    if (reply.data.status !== 200 ){
        return null;
    }
    else{
        localStorage.setItem("jwt", JSON.stringify(reply.data.jwt));
        return reply.data.jwt;
    }
};

const logout = () => {
    localStorage.removeItem('uat');
    window.location.href = "/login";
};

const getCurrentToken = () => {
    return JSON.parse(localStorage.getItem('jwt'));
};

const getCurrentUser = async () => {
    var token = getCurrentToken();
    const response = axios.post(API_URL + 'kitchen-get-by-token.php?jwt=' + token);

    const reply = await response;
    if( reply.data.status !== 200 ){
        //window.location.href = "/loginotp";
        return null;
    }
    console.log("logged user : " + reply.data);
    //console.log("kitchen id from service : " + reply.data.data.id);
    //console.log("jwt from service : " + reply.data.data.user.token);
    var data = reply.data;
    return data;
};

const AuthFunctions = {
    login,
    sentLoginOtp,
    logout,
    getCurrentUser,
    getCurrentToken
};

export default AuthFunctions;
