import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";
import { useNavigate } from 'react-router-dom';

//create function component as login
export default function OrderDetail() {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <Link onClick={handleGoBack} className="text-success"> Go Back </Link>
                    <ul className="earning-list mt-0">
                        <li>
                            <a href="order-details.html">
                                <div className="earning-box">
                                    <div className="earning-img">
                                        <img className="img-fluid earning-icon" src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                    </div>

                                    <div className="earning-content">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="light-text fw-medium">Order No</h6>
                                            <h6 className="fw-normal light-text">Today at 12:05 am</h6>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <h6 className="fw-mediun dark-text">#1252</h6>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="payment-box order-payment-details">
                                <div className="box-content">
                                    <h6 className="light-text ps-0 border-0">Payment Method</h6>
                                    <h5 className="success-color mt-1">G-Pay</h5>
                                </div>
                                <div className="box-content border-0 ps-4">
                                    <h6 className="light-text ps-0 border-0">Total Payment</h6>
                                    <h5 className="dark-text mt-1">$40.50</h5>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div className="order-item-table">
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th>Order Items</th>
                                    <th className="text-center">Qnt.</th>
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Veg Sandwich</td>
                                    <td className="text-center">1</td>
                                    <td className="text-end">$10.00</td>
                                </tr>
                                <tr>
                                    <td>Veg Frankie <a href="#custom1" data-bs-toggle="modal">Customized</a> </td>
                                    <td className="text-center">2</td>
                                    <td className="text-end">$20.00</td>
                                </tr>
                                <tr>
                                    <td>Veg Burger</td>
                                    <td className="text-center">1</td>
                                    <td className="text-end">$5.00</td>
                                </tr>
                                <tr>
                                    <td>Margherita Pizza</td>
                                    <td className="text-center">1</td>
                                    <td className="text-end">$15.00</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td>Tax(10%)</td>
                                    <td></td>
                                    <td className="text-end">$2.00</td>
                                </tr>
                                <tr>
                                    <td>Delivery Charges</td>
                                    <td></td>
                                    <td className="text-end">$3.00</td>
                                </tr>
                            </tbody>
                            <tbody className="border-0">
                                <tr>
                                    <td className="fw-semibold dark-text pb-0">Sub Total</td>
                                    <td></td>
                                    <td className="text-end fw-semibold theme-color sub-total pb-0">$55.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="shipping-details">
                        <h5 className="title-content">Shipping Details</h5>

                        <div className="shipping-content">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex align-items-center gap-2 w-100">
                                    <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                    <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                </div>

                                <div className="d-flex align-items-center flex-nowrap gap-2 w-100">
                                    <img className="img-fluid icon" src="../assets/images/svg/call-line.svg" alt="wallet" />
                                    <h6 className="fw-medium dark-text">+1(202)3331146</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-2 mt-2">
                                <img className="img-fluid icon" src="../assets/images/svg/sms.svg" alt="wallet" />
                                <h6 className="dark-text fw-medium">amoughhegde21@gmail.com</h6>
                            </div>

                            <div className="d-flex align-items-center gap-2 mt-2">
                                <img className="img-fluid icon" src="../assets/images/svg/location.svg" alt="wallet" />
                                <h6 className="fw-medium dark-text">18 Thornridge Cir. Syracuse, Connecticut USA</h6>
                            </div>
                        </div>
                    </div>

                    <p>Note : You can make multiple food orders at one tile you can also cancel orders.</p>
                    <div className="notify-part">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label fw-medium dark-text" htmlFor="flexCheckDefault">Notify the delivery boy of the new order</label>
                        </div>
                        <button type="button" className="w-100 mt-6 btn btn-info"> Put To In Progress </button>
                    </div>
                </div>

            </section>

            <MenuFooter />

            {/* modal popup  */}
            <div className="modal order-modal fade" id="custom1" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Veg Frankie</h3>
                        </div>
                        <div className="modal-body">
                            <div className="order-item-table">
                                <table className="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th colSpan={3}>Dips</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Chilli</td>
                                            <td className="text-center">1</td>
                                            <td className="text-end">Rs. 40.00</td>
                                        </tr>
                                        <tr>
                                            <td>Mayonesse</td>
                                            <td className="text-center">2</td>
                                            <td className="text-end">RS. 20.00</td>
                                        </tr>
                                    </tbody>
                                    <tbody className="border-0">
                                        <tr>
                                            <td className="fw-semibold dark-text pb-0">Menu Total</td>
                                            <td></td>
                                            <td className="text-end fw-semibold theme-color sub-total pb-0">Rs. 55.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}