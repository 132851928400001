import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function KitchenMenuAdd() {

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="header">
                        <div className="head-content">
                            <h2>Create New Menu</h2>
                        </div>
                        <Link to="/kitchenmenulist">
                            <i className="ri-list-check"></i>
                        </Link>
                    </div>
                    <form className="auth-form" target="_blank">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label fw-semibold">Image</label>
                                    <input type="file" className="form-control shadow-none" placeholder="Food image" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label fw-semibold">Title</label>
                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Short Description</label>
                                    <textarea className="form-control shadow-none" placeholder="Will be displayed to customer"></textarea>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Ingredients Details</label>
                                    <textarea className="form-control shadow-none" placeholder="For personal use only"></textarea>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Price</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter price" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Discount</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter discount" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Tax</label>
                                    <input type="number" className="form-control shadow-none" placeholder="Enter tax" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Food Category</label>
                                    <select className="form-select shadow-none" aria-label="Default select example" defaultValue="2">
                                        <option value="1">Parootta</option>
                                        <option value="2">Biriyani</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Food Type</label>
                                    <select className="form-select shadow-none" aria-label="Default select example" defaultValue="2">
                                        <option value="1">Veg</option>
                                        <option value="2">Non Veg</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Prepare Type</label>
                                    <select className="form-select shadow-none" aria-label="Default select example" defaultValue="2">
                                        <option value="1">Normal</option>
                                        <option value="2">Chettinad</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Want Custom Option</label>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="buyerType" id="buyerType1" />
                                        <label className="form-check-label dark-text" htmlFor="buyerType1">Yes</label>
                                    </div>

                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="buyerType" id="buyerType2" />
                                        <label className="form-check-label dark-text" htmlFor="buyerType2">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div id="customMenuArea">
                                    <div className="customMenuList">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Option Name</label>
                                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Base Price</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Enter price" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Min Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Max Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Option Name</label>
                                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Base Price</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Enter price" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Min Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Max Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Option Name</label>
                                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Base Price</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Enter price" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Min Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Max Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Option Name</label>
                                                    <input type="text" className="form-control shadow-none" placeholder="Enter name" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Base Price</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Enter price" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Min Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-3">
                                                    <label className="form-label fw-semibold">Max Unit</label>
                                                    <input type="number" className="form-control shadow-none" placeholder="Count" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <button type="button" className="btn theme-btn mt-0 w-100">Add</button>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <button type="reset" className="btn gray-btn mt-0 w-100">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}