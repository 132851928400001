import { Link, useParams } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function OrderSearch() {

    const { status } = useParams();

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <form className="auth-form" target="_blank">
                        <h4>Search Order - <b>{status.toUpperCase()}</b></h4>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">From Date</label>
                                    <input type="date" className="form-control shadow-none" placeholder="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">To Date</label>
                                    <input type="date" className="form-control shadow-none" placeholder="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Order Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="" readOnly />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-3">
                                    <label className="form-label fw-semibold">Mobile Number</label>
                                    <input type="number" className="form-control shadow-none" placeholder="" readOnly />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <button type="button" className="w-100 btn btn-success"> Load Orders </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <ul className="earning-list">
                    <li>
                        <Link to="/orderdetail">
                            <div className="earning-box">
                                <div className="earning-img">
                                    <img className="img-fluid earning-icon"
                                        src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                </div>

                                <div className="earning-content flex-column">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="light-text fw-medium">Order No</h6>
                                        <h6 className="fw-normal light-text">1 min ago</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        <h6 className="fw-mediun dark-text">#1252</h6>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="payment-box order-person-details">
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                    <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                </div>

                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <img className="img-fluid icon"
                                        src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                    <h6 className="fw-medium dark-text">Rs. 32</h6>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                        alt="wallet" />
                                    <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                </div>

                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                        alt="wallet-money" />
                                    <h6 className="fw-medium dark-text">COD</h6>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <Link to="/orderdetail">
                            <div className="earning-box">
                                <div className="earning-img">
                                    <img className="img-fluid earning-icon"
                                        src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                </div>

                                <div className="earning-content flex-column">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="light-text fw-medium">Order No</h6>
                                        <h6 className="fw-normal light-text">1 min ago</h6>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-2">
                                        <h6 className="fw-mediun dark-text">#1252</h6>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="payment-box order-person-details">
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                    <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                </div>

                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <img className="img-fluid icon"
                                        src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                    <h6 className="fw-medium dark-text">Rs. 32</h6>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                        alt="wallet" />
                                    <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                </div>

                                <div className="d-flex align-items-center gap-2 mt-2">
                                    <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                        alt="wallet-money" />
                                    <h6 className="fw-medium dark-text">COD</h6>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>

            <MenuFooter />
        </>
    );
}