import React, { createContext, useState, useContext } from 'react';

// Create a Context
const GlobalContext = createContext();

// Create a Provider Component
export const GlobalProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAppError, setAppError] = useState(false);
    const [appErrorMessage, setAppErrorMessage] = useState("");
    const [appKitchen, setAppKitchen] = useState(null);

  return (
    <GlobalContext.Provider value={{ isLoading, setIsLoading, isAppError, setAppError, appErrorMessage, setAppErrorMessage, appKitchen, setAppKitchen }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom Hook for Accessing Context
export const useGlobalContext = () => useContext(GlobalContext);
