import axios from 'axios';
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_URL;

const getKyc = async () => {
    const jwt = AuthService.getCurrentToken();

    const response = axios.get(API_URL + 'kitchen-kyc-get-by-token.php?jwt=' + jwt);
    const reply = await response;
    if( reply.data.status !== 200 ){
        //window.location.href = "/loginotp";
        return null;
    }
    console.log("kitchen kyc : " + reply.data);
    var data = reply.data;
    return data;
};

const KitchenKycFunctions = {
    getKyc
};

export default KitchenKycFunctions;
