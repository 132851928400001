//create function component as login
export default function Home() {

    return (
        <>
            <section className="section-b-space pt-0">

                <div className="welcome-container">

                    <img className="img-fluid welcome-img" src="/assets/system/oonavoo-logo.jpg" alt="login-img" />

                    <h2 className="text-center">Welcome To Oonavoo</h2>

                    <p className="text-center">Have a healthy food with our community</p>

                    <a href="/login" className="btn theme-btn w-100 mt-4" role="button">Way To Kitchen</a>

                    <p className="text-center">By continuing, you agree to our Terms of service Privacy Policy Content Policy</p>
                </div>
            </section>
        </>
    );
}