import { useState } from "react";
import MenuFooter from "./MenuFooter";
import MenuSide from "./MenuSide";
import { Link } from "react-router-dom";

//import Kitcenservice
//import KitchenService from "./service/KitchenService";
import { useEffect } from 'react';
import { useGlobalContext } from "./GlobalContext";
import AuthFunctions from "./service/AuthService";
import KitchenFunctions from "./service/KitchenService";

//create function component as login
export default function Dashboard() {

    const { appKitchen, setIsLoading } = useGlobalContext();
    const [openStatus, setOpenStatus] = useState(false);

    const toggleOpenStatus = () => {
        var status = 'closed';
        if( openStatus === false ){
            status = 'active';
        }
        setIsLoading(true);
        KitchenFunctions.changeStatus(status);
        setOpenStatus(!openStatus);
        setIsLoading(false);
    };

    //function to get all the cities
    const makeDashboard = async () => {
        if( appKitchen.status === 'active' ){
            setOpenStatus(true);
        }
        else if( appKitchen.status === 'closed' ){
            setOpenStatus(false);
        } 
    }

    //useEffect to call getAllCitys on component load
    useEffect(() => {
        makeDashboard();
    }, []);

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="status-box">
                        <div>
                            <h5>Available Status</h5>
                            {openStatus ? (
                                <h6>Restaurant Open</h6>
                            ): (
                                <h6 className="text-danger">Restaurant Closed</h6>
                            )}
                        </div>
                        <div className="switch-btn">
                            <input id="dir-switch" type="checkbox" checked={openStatus} onChange={toggleOpenStatus} />
                        </div>
                    </div>

                    <div className="total-sales-background mt-3">
                        <div className="earning-card-details">
                            <div className="w-100">
                                <div className="d-flex align-items-center">
                                    <img className="img-fluid icon " src="../assets/images/svg/empty-wallet.svg" alt="wallet-money" />
                                    <div className="ps-2">
                                        <h5 className="dark-text">Total Sales For The Day</h5>
                                    </div>
                                    <h2 className="ms-auto"><i className="ri-money-rupee-circle-line"></i> 23</h2>
                                </div>
                            </div>
                        </div>

                        <ul className="sale-timing-list">
                            <li className="w-100">
                                <div className="sale-timing-box">
                                    <h6 className="fw-normal dark-text">This Week</h6>
                                    <h3 className="fw-semibold theme-color"><i className="ri-money-rupee-circle-line"></i> 126</h3>
                                </div>
                            </li>
                            <li className="w-100">
                                <div className="sale-timing-box">
                                    <h6 className="fw-normal dark-text">This Month</h6>
                                    <h3 className="fw-semibold theme-color"><i className="ri-money-rupee-circle-line"></i> 795</h3>
                                </div>
                            </li>
                            <li className="w-100">
                                <div className="sale-timing-box">
                                    <h6 className="fw-normal dark-text">This Year</h6>
                                    <h3 className="fw-semibold theme-color"><i className="ri-money-rupee-circle-line"></i> 9850</h3>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <ul className="profile-details-list order-list restaurant-order-list mt-4 mb-0">
                        <li className="w-100">
                            <img className="img-fluid icon ms-auto mb-0" src="../assets/images/delivery-boy/svg/box.svg" alt="box" />
                            <h2>15</h2>
                            <h6>Order</h6>
                        </li>
                        <li className="w-100">
                            <img className="img-fluid icon ms-auto mb-0" src="../assets/images/delivery-boy/svg/box-time.svg"
                                alt="box" />
                            <h2>03</h2>
                            <h6>Pending</h6>
                        </li>
                        <li className="w-100">
                            <img className="img-fluid icon ms-auto mb-0" src="../assets/images/delivery-boy/svg/3d-rotate.svg"
                                alt="box" />
                            <h2>02</h2>
                            <h6>active</h6>
                        </li>
                        <li className="w-100">
                            <img className="img-fluid icon ms-auto mb-0" src="../assets/images/delivery-boy/svg/box-tick.svg"
                                alt="box" />
                            <h2>10</h2>
                            <h6>Completed</h6>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="title">
                        <h3 className="mt-0">Order update</h3>
                        <Link>See All</Link>
                    </div>

                    <ul className="nav nav-pills restaurant-order-tab tab-style2 w-100 border-0 m-0" id="Tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pill-pending-tab" data-bs-toggle="pill"
                                data-bs-target="#pending-tab">Pending</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pill-preparing-tab" data-bs-toggle="pill"
                                data-bs-target="#preparing-tab">Preparing</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pill-delivery-tab" data-bs-toggle="pill"
                                data-bs-target="#delivery-tab">Out
                                for Delivery</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pill-delivered-tab" data-bs-toggle="pill"
                                data-bs-target="#delivered-tab">Delivered</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pill-reject-tab" data-bs-toggle="pill"
                                data-bs-target="#reject-tab">Reject</button>
                        </li>
                    </ul>

                    <div className="tab-content restaurant-content" id="TabContent">
                        <div className="tab-pane fade show active" id="pending-tab">
                            <ul className="earning-list">
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <h6 className="fw-normal light-text">1 min ago</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <h6 className="fw-normal light-text">1 min ago</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-pane fade" id="preparing-tab">
                            <ul className="earning-list">
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <h6 className="fw-normal light-text">1 min ago</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between  mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="btn theme-btn w-100 mt-3">Ready to Deliver</Link>
                                </li>

                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <h6 className="fw-normal light-text">1 min ago</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="btn theme-btn w-100 mt-3">Ready to Deliver</Link>
                                </li>
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <h6 className="fw-normal light-text">1 min ago</h6>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="btn theme-btn w-100 mt-3">Ready to Deliver</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-pane fade" id="delivery-tab">
                            <ul className="earning-list">
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="accent-color">Ongoing</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rider-list mt-2">
                                        <li className="dark-text"><span className="light-text">Rider</span> Johnny</li>
                                        <li className="dark-text"><span className="light-text">Number</span>+1(121)524880</li>
                                        <li className="dark-text"><span className="light-text">25 min ago</span></li>
                                    </ul>
                                </li>

                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="accent-color">Ongoing</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rider-list mt-2">
                                        <li className="dark-text"><span className="light-text">Rider</span>Alexander</li>
                                        <li className="dark-text"><span className="light-text">Number</span>+1(101)523590</li>
                                        <li className="dark-text"><span className="light-text">25 min ago</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-pane fade" id="delivered-tab">
                            <ul className="earning-list">
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="success-color">Delivered</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rider-list mt-2">
                                        <li className="dark-text"><span className="light-text">Rider</span> Johnny</li>
                                        <li className="dark-text"><span className="light-text">Number</span>+1(121)524880</li>
                                        <li className="dark-text"><span className="light-text">25 min ago</span></li>
                                    </ul>
                                </li>

                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="success-color">Delivered</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="rider-list mt-2">
                                        <li className="dark-text"><span className="light-text">Rider</span>Alexander</li>
                                        <li className="dark-text"><span className="light-text">Number</span>+1(101)523590</li>
                                        <li className="dark-text"><span className="light-text">25 min ago</span></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-pane fade" id="reject-tab">
                            <ul className="earning-list">
                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="error-color">Reject</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-date mt-2">
                                        <h6>Order Date : 04/01/2024 | 12:43 pm</h6>
                                    </div>
                                </li>

                                <li>
                                    <Link>
                                        <div className="earning-box">
                                            <div className="earning-img">
                                                <img className="img-fluid earning-icon"
                                                    src="../assets/images/delivery-boy/svg/receipt.svg" alt="receipt" />
                                            </div>

                                            <div className="earning-content flex-column">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h6 className="light-text fw-medium">Order No</h6>
                                                    <span className="error-color">Reject</span>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-2">
                                                    <h6 className="fw-mediun dark-text">#1252</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="payment-box order-person-details pb-0 pb-0">
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/user.svg" alt="wallet" />
                                                <h6 className="dark-text fw-medium">Amough Hegde</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon"
                                                    src="../assets/images/delivery-boy/svg/wallet-money.svg" alt="wallet-money" />
                                                <h6 className="fw-medium dark-text"><i className="ri-money-rupee-circle-line"></i> 32</h6>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/call-line.svg"
                                                    alt="wallet" />
                                                <h6 className="dark-text fw-medium">+1-202-444-0146</h6>
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mt-2">
                                                <img className="img-fluid icon" src="../assets/images/svg/empty-wallet.svg"
                                                    alt="wallet-money" />
                                                <h6 className="fw-medium dark-text">COD</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-date mt-2">
                                        <h6>Order Date : 04/01/2024 | 12:43 pm</h6>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <MenuFooter />

        </>
    );
}