import axios from 'axios';
import AuthService from "./AuthService";

const API_URL = process.env.REACT_APP_API_URL;

const changeStatus = async (status) => {
    const jwt = AuthService.getCurrentToken();
    let formData = new FormData();
    formData.append('status', status);

    const response = axios.post(API_URL + 'kitchen-change-open-status.php?jwt=' + jwt, formData);
    const reply = await response;
    console.log(reply.data);
    if (reply.data.status === 200 ){
        //AuthService.getCurrentUser();
        return true;
    }
    else{
        return false;
    }
};

const updateDetails = async (name, bio, addressLine1, addressLine2, pincode) => {
    const jwt = AuthService.getCurrentToken();
    let formData = new FormData();
    formData.append('name', name);
    formData.append('bio', bio);
    formData.append('addressLine1', addressLine1);
    formData.append('addressLine2', addressLine2);
    formData.append('pincode', pincode);

    const response = axios.post(API_URL + 'kitchen-update-details.php?jwt=' + jwt, formData);
    const reply = await response;
    console.log(reply.data);
    if (reply.data.status === 200 ){
        return true;
    }
    else{
        return false;
    }
};  

const updateContact = async (mobilePrimary, mobileAlternate, emailPrimary, emailAlternate) => {
    const jwt = AuthService.getCurrentToken();
    let formData = new FormData();
    formData.append('mobilePrimary', mobilePrimary);
    formData.append('mobileAlternate', mobileAlternate);
    formData.append('emailPrimary', emailPrimary);
    formData.append('emailAlternate', emailAlternate);

    const response = axios.post(API_URL + 'kitchen-update-contact.php?jwt=' + jwt, formData);
    const reply = await response;
    console.log(reply.data);
    if (reply.data.status === 200 ){
        return true;
    }
    else{
        return false;
    }
};  

const KitchenFunctions = {
    changeStatus,
    updateDetails,
    updateContact
};

export default KitchenFunctions;
