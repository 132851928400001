import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function KitchenMenuList() {

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="header">
                        <div className="head-content">
                            <h2>My Kitchen Menu</h2>
                        </div>
                        <Link to="/kitchemenuadd">
                            <i className="ri-add-box-fill"></i>
                        </Link>
                    </div>
                    <div className="btn-block">
                        <Link className="btn active"> Active </Link>
                        <Link className="btn"> Inactive </Link>
                    </div>
                    <div class="row gy-3">
                        <div class="col-12">
                            <div class="vertical-product-box order-box">
                                <div class="vertical-box-img">
                                    <img class="img-fluid img" src="../assets/images/product/vp1.png" alt="vp1" />
                                </div>

                                <div class="vertical-box-details">
                                    <div class="vertical-box-head">
                                        <div class="restaurant">
                                            <h5 class="dark-text">Ben & Floren</h5>
                                            <h5 class="theme-color">$10</h5>
                                        </div>
                                        <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                                    </div>
                                    <div class="reorder d-flex align-items-center justify-content-between mt-2">
                                        <span class="fw-normal success-color">Available</span>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="vertical-product-box order-box">
                                <div class="vertical-box-img">
                                    <img class="img-fluid img" src="../assets/images/product/vp2.png" alt="vp2" />
                                </div>

                                <div class="vertical-box-details">
                                    <div class="vertical-box-head">
                                        <div class="restaurant">
                                            <h5 class="dark-text">242 Pizza</h5>
                                            <h5 class="theme-color">$18.40</h5>
                                        </div>
                                        <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                                    </div>
                                    <div class="reorder d-flex align-items-center justify-content-between mt-2">
                                        <span class="fw-normal success-color">Available</span>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="vertical-product-box order-box">
                                <div class="vertical-box-img">
                                    <img class="img-fluid img" src="../assets/images/product/vp3.png" alt="vp3" />
                                </div>

                                <div class="vertical-box-details">
                                    <div class="vertical-box-head">
                                        <div class="restaurant">
                                            <h5 class="dark-text">AWS - USA</h5>
                                            <h5 class="theme-color">$18.40</h5>
                                        </div>
                                        <h6 class="food-items mb-2">Chicken quesadilla, avocado...</h6>
                                    </div>
                                    <div class="reorder d-flex align-items-center justify-content-between mt-2">
                                        <span class="fw-normal success-color">Available</span>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}