import { Link } from "react-router-dom";
import MenuSide from "./MenuSide";
import MenuFooter from "./MenuFooter";

//create function component as login
export default function CouponList() {

    return (
        <>
            <MenuSide />

            <section className="section-b-space">
                <div className="custom-container">
                    <div className="header">
                        <div className="head-content">
                            <h2>List My Coupons</h2>
                        </div>
                        <Link to="/couponadd">
                            <i className="ri-add-box-fill"></i>
                        </Link>
                    </div>
                    <div className="btn-block">
                        <Link className="btn active"> Active </Link>
                        <Link className="btn"> Inactive </Link>
                    </div>
                    <ul class="coupon-list">
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">20% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Use Code AHNBGT234</h6>
                                        <h6 class="fw-normal dark-text">Total User : 08</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 1-10-2023 to 20-12-2023</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">20% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Use Code AHNBGT234</h6>
                                        <h6 class="fw-normal dark-text">Total User : 08</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 1-10-2023 to 20-12-2023</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="offer-box">
                                <div class="offer-icon">
                                    <img class="img-fluid offer" src="../assets/images/icons/Offer-Discount.png" alt="offer" />
                                </div>
                                <div class="offer-content">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <a href="#coupon" data-bs-toggle="modal">
                                            <h3 class="fw-semibold dark-text">20% OFF</h3>
                                        </a>
                                        <div class="switch-btn">
                                            <input type="checkbox" />
                                        </div>
                                    </div>
                                    <div>
                                        <h6 class="light-text">Use Code AHNBGT234</h6>
                                        <h6 class="fw-normal dark-text">Total User : 08</h6>
                                        <h6 class="fw-normal dark-text">Valid Until : 1-10-2023 to 20-12-2023</h6>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <MenuFooter />
        </>
    );
}